import $api from "../http";
import {AxiosResponse} from 'axios';
import {AuthResponse} from '../models/response/AuthResponce';

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/login', {email, password})
    }

    static async registration(email: string, password: string, name: string, surname: string, patronymic: string, status: string, companyName: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/registration', {email, password, name, surname, patronymic, status, companyName})
    }

    static async logout(): Promise<void> {
        return $api.post('/logout')
    }

}