import React, {FC, useContext, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import { Alert, Col, Container, Row } from 'react-bootstrap';

import mainImg from "../img/login/main.png";
import ModalDefault from './modals/ModalDefault';
import { Navigate } from 'react-router-dom';
const RegisterForm: FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [patronymic, setPatronymic] = useState<string>('')
    const [status, setStatus] = useState<string>('myself')
    const [companyName, setCompanyName] = useState<string>('')
    const {store} = useContext(Context);

    const handleInputChange = (score: any) => {
        setStatus(score);
    };

    return (<><Container>
        <Row className="form-container my-5 justify-content-between">
            <Col className="mb-5 mb-lg-0" lg={4}>
                <h4 className='mb-5'>Введите данные, <br/>необходимые для регистрации на платформе:</h4>
                {store.errorMessage !== '' ?
                    <Alert variant="danger">{store.errorMessage}</Alert> :
                    ''
                }
                <Row>
                    <Col lg={4}>
                        <div className='form-container-item mb-4'>
                            <div className='mb-1'><label htmlFor="form-container-name">Имя</label></div>
                            <input
                                className='w-100'
                                onChange={e => setName(e.target.value)}
                                value={name}
                                type="text"
                                placeholder='Имя'
                                id="form-container-name"
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='form-container-item mb-4'>
                            <div className='mb-1'><label htmlFor="form-container-surname">Фамилия</label></div>
                            <input
                                className='w-100'
                                onChange={e => setSurname(e.target.value)}
                                value={surname}
                                type="text"
                                placeholder='Фамилия'
                                id="form-container-surname"
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='form-container-item mb-4'>
                            <div className='mb-1'><label htmlFor="form-container-patronymic">Отчество</label></div>
                            <input
                                className='w-100'
                                onChange={e => setPatronymic(e.target.value)}
                                value={patronymic}
                                type="text"
                                placeholder='Отчество'
                                id="form-container-patronymic"
                            />
                        </div>
                    </Col>
                </Row>
                
                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-email">Адрес электронной почты</label></div>
                    <input
                        className='w-100'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        placeholder='Электронная почта'
                        id="form-container-email"
                    />
                </div>

                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-status">Выберите статус</label></div>
                    <div className="radio">
                        <input
                            onChange={e => handleInputChange('myself')}
                            checked={'myself' === status}
                            name="status"
                            type="radio"
                            value={status}
                            id="form-container-myself"
                        />
                        <label className="fw-normal radio-label" htmlFor="form-container-myself">Я представляю свои интересы</label>
                    </div>
                    <div className="radio">
                        <input
                            onChange={e => handleInputChange('company')}
                            checked={'company' === status}
                            name="status"
                            value={status}
                            type="radio"
                            id="form-container-company"
                        />
                        <label className="fw-normal radio-label" htmlFor="form-container-company">Я представляю интересы Юридического лица</label>
                    </div>
                </div>

                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-companyName">Полное наименование юридического лица</label></div>
                    <input
                        className='w-100'
                        onChange={e => setCompanyName(e.target.value)}
                        value={companyName}
                        type="text"
                        placeholder='ООО "Наименование компании"'
                        id="form-container-companyName"
                    />
                </div>

                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-password">Пароль</label></div>
                    <input
                        className='w-100'
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        placeholder='Пароль'
                        id="form-container-password"
                    />
                </div>
                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-confirm-password">Подвердите Пароль</label></div>
                    <input
                        className='w-100'
                        onChange={e => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        type="password"
                        placeholder='Подвердите Пароль'
                        id="form-container-confirm-password"
                    />
                </div>
                <div className='form-container-item my-2'>
                    <Row className="justify-content-between align-items-center">
                        <Col lg={3}>
                            <button className="btn btn-primary" onClick={() => store.registration(email, password, confirmPassword, name, surname, patronymic, status, companyName)}>
                                Регистрация
                            </button>
                        </Col>
                    </Row>
                </div>
                
                <div className='form-container-links my-5 d-flex'>
                    <ModalDefault name="Вход через Госуслуги" class="btn-outline-wrap-gold"/>
                    <ModalDefault name="Вход через ФНС" class="ms-5 btn-outline-wrap-gold"/>
                </div>

            </Col>
            <Col className="mb-5 mb-lg-0 form-container-img" lg={7}>
                <img  className="w-100" src={mainImg} alt="Login page"/>
            </Col>
        </Row>
        {store.isAuth ? <Navigate to="/account" replace={true} /> : ''}
        </Container></>
    );
};

export default observer(RegisterForm);