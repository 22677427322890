import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import envelope from "../../img/icons/Envelope.svg";

function ModalDefault(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <div className="btn-outline-wrap-gold me-5">
      <Button variant="outline" onClick={handleShow}>
        {props.name}
      </Button>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4>Совсем скоро будут доступны все возможности нашей платформы!</h4>
          <p>Но пока данное окно находится в разработке</p>
        </Modal.Body>
        <Modal.Footer>
          <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
          <div className='btn-outline-wrap-green mt-3 mb-4'>
              <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
            </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDefault;