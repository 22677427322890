import $api from "../http";
import {AxiosResponse} from 'axios';
import {GenerelInfoResponse} from '../models/response/GenerelInfoResponse';

export default class GenerelInfoService {
    static async saveGenerelInfo(
        email: string,
        user: string,
        reprFio: string,
        repName: string,
        reprPatronimic: string,
        dateDateBirthday: string,
        reprPhone: string,
        reprEmail: string,
        reprInn: string,
        reprSNILS: string,
        reprBirthdayPlace: string,
        reprNationality: string,
        reprPasport: string,
        reprSerial: string,
        reprPasportNumber: string,
        pasportDate: string,
        reprCodeDepartment: string,
        reprIssuedBy: string,
        uploadPasportDoc: File,
        reprRegRegion: string,
        reprRegCity: string,
        reprRegStreet: string,
        reprRegHouse: string,
        reprRegCorpus: string,
        reprRegApp: string,
        reprPostalCode: string,
        uploadRegistrationDoc: File,
        isLiveAddSameAsReg: string,
        isPostAddSameAsReg: string,
        addrLiveRegion: string,
        addrLiveCity: string,
        addrLiveStreet: string,
        addrLiveHouse: string,
        addrLiveCorpus: string,
        addrLiveApp: string,
        addrLivePostalCode: string,
        postLiveRegion: string,
        postLiveCity: string,
        postLiveStreet: string,
        postLiveHouse: string,
        postLiveCorpus: string,
        postLiveApp: string,
        postLivePostalCode: string
        
        ): Promise<AxiosResponse<GenerelInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('reprFio', reprFio);
        formData.append('repName', repName);
        formData.append('reprPatronimic', reprPatronimic);
        formData.append('dateDateBirthday', dateDateBirthday);
        formData.append('reprPhone', reprPhone);
        formData.append('reprEmail', reprEmail);
        formData.append('reprInn', reprInn);
        formData.append('reprSNILS', reprSNILS);
        formData.append('reprBirthdayPlace', reprBirthdayPlace);
        formData.append('reprNationality', reprNationality);
        formData.append('reprPasport', reprPasport);
        formData.append('reprSerial', reprSerial);
        formData.append('reprPasportNumber', reprPasportNumber);
        formData.append('pasportDate', pasportDate);
        formData.append('reprCodeDepartment', reprCodeDepartment);
        formData.append('reprIssuedBy', reprIssuedBy);
        formData.append('uploadPasportDoc', uploadPasportDoc);
        formData.append('reprRegRegion', reprRegRegion);
        formData.append('reprRegCity', reprRegCity);
        formData.append('reprRegStreet', reprRegStreet);
        formData.append('reprRegHouse', reprRegHouse);
        formData.append('reprRegCorpus', reprRegCorpus);
        formData.append('reprRegApp', reprRegApp);
        formData.append('reprPostalCode', reprPostalCode);
        formData.append('uploadRegistrationDoc', uploadRegistrationDoc);
        formData.append('isLiveAddSameAsReg', isLiveAddSameAsReg);
        formData.append('isPostAddSameAsReg', isPostAddSameAsReg);
        formData.append('addrLiveRegion', addrLiveRegion);
        formData.append('addrLiveCity', addrLiveCity);
        formData.append('addrLiveStreet', addrLiveStreet);
        formData.append('addrLiveHouse', addrLiveHouse);
        formData.append('addrLiveCorpus', addrLiveCorpus);
        formData.append('addrLiveApp', addrLiveApp);
        formData.append('addrLivePostalCode', addrLivePostalCode);
        formData.append('postLiveRegion', postLiveRegion);
        formData.append('postLiveCity', postLiveCity);
        formData.append('postLiveStreet', postLiveStreet);
        formData.append('postLiveHouse', postLiveHouse);
        formData.append('postLiveCorpus', postLiveCorpus);
        formData.append('postLiveApp', postLiveApp);
        formData.append('postLivePostalCode', postLivePostalCode);

        return $api.post<GenerelInfoResponse>('/generelInfoForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}