import $api from "../http";
import {AxiosResponse} from 'axios';
import {ConfirmInfoResponse} from '../models/response/ConfirmInfoResponse';

export default class ConfirmInfoService {
    static async saveConfirmInfo(
        email: string,
        user: string,
        confirmName: string,
        confirmNumber: string,
        dateAccessStart: string,
        dateAccessEnd: string,
        uploadConfirmDoc: File
        ): Promise<AxiosResponse<ConfirmInfoResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('confirmName', confirmName);
        formData.append('confirmNumber', confirmNumber);
        formData.append('dateAccessStart', dateAccessStart);
        formData.append('dateAccessEnd', dateAccessEnd);
        formData.append('uploadConfirmDoc', uploadConfirmDoc);

        return $api.post<ConfirmInfoResponse>('/confirmInfoForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}