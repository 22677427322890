import React from "react";
import parse from 'html-react-parser';
import { Button, Col, Container, Row } from "react-bootstrap";

import myData from './../locale/ru.json';

import itemImg from "./../img/main/main-list-item.png"
import { Link } from "react-router-dom";

const Home = () => {
    return ( 
        <><Container>
            <div className="main-banner">
                <div className="main-banner-wrapp">
                    <h1 className="mb-4">{myData.logo}</h1>
                    <p className="mb-5 h3 fw-normal">{myData.mainText}</p>
                    <div className="d-flex justify-content-center">
                    <Link to="/about" className="btn-outline-wrap-green mx-4"><Button variant="outline">{myData.buttons.lernMore}</Button></Link>
                    <Link to="/registration" className="mx-4 btn btn-secondary">{myData.buttons.registration}</Link>
                </div>
                </div>
            </div>
            <div className="main-description">
                <Row className="mb-5 justify-content-between">
                    <Col className="mb-4 mb-lg-0" lg={6}>
                        <div className="main-title">{myData.home.header2}</div>
                    </Col>
                    <Col lg={5} className="text-lg-end main-description-right">
                        <p>{parse(myData.home.p1)}</p>
                        <p>{parse(myData.home.p2)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-5 mb-lg-0" lg={4}>
                        <div className="content-item-img"><img src={itemImg} alt={myData.logo}/></div>
                        <div className="content-item-title">{myData.home.pages.title1}</div>
                        <div className="content-item-description">{myData.home.pages.description1}</div>
                    </Col>
                    <Col className="mb-5 mb-lg-0" lg={4}>
                        <div className="content-item-img"><img src={itemImg} alt={myData.logo}/></div>
                        <div className="content-item-title">{myData.home.pages.title2}</div>
                        <div className="content-item-description">{myData.home.pages.description2}</div>
                    </Col>
                    <Col lg={4}>
                        <div className="content-item-img"><img src={itemImg} alt={myData.logo}/></div>
                        <div className="content-item-title">{myData.home.pages.title3}</div>
                        <div className="content-item-description">{myData.home.pages.description3}</div>
                    </Col>
                </Row>
            </div>
        </Container>
    </>);
}
 
export default Home;