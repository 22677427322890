import { Link } from "react-router-dom";

const Investors = () => {
    return ( <>
        <div className="text-center my-5">
            <h1>Инвесторам</h1>
            <h3>Страница в разработке ...</h3>
            <Link to="/">Home</Link>
        </div></>
    );
}
 
export default Investors;