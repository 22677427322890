import { useContext } from "react";
import { Alert, Card, CardBody, CardTitle, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import {Context} from "../../index";

import avatars from "../../img/avatars.png";
import edit from "../../img/icons/Edit.svg";
import editWhite from "../../img/icons/Edit-white.svg";
import beidges from "../../img/icons/beidges.png";
import ModalLink from "../modals/ModalLink";
import TooltipInfo from "../tooltips/TooltipInfo";

const AccountNewCustomer = () => {
    const {store} = useContext(Context);
    let statusLableText:string = 'Черновик';
    let statusLableClass:string = 'continue';
    let statusLableAlert:string = 'dark';
    let statusLableAlertText:string = 'Продолжите заполнение всех анкет для получения полного доступа к платформе';
    if (store.bank.isFormCompleated && store.bank.isFormValidated && store.bank.isFormValid) {
        statusLableText = 'Подтверждено';
        statusLableClass = 'done';
        statusLableAlert = 'success';
        statusLableAlertText = 'Заполнненая Вами форма прошла верификацию'
    } else if (store.bank.isFormCompleated && store.bank.isFormValidated && !store.bank.isFormValid) {
        statusLableText = 'НЕОБХОДИМА ДОРАБОТКА';
        statusLableClass = 'faild';
        statusLableAlert = 'danger';
        statusLableAlertText = 'Внесите указанные изменения в анкету и отправьте на повторную проверку'
    } else if (store.bank.isFormCompleated) {
        statusLableText = 'На проверке';
        statusLableClass = 'checking';
        statusLableAlert = 'warning';
        statusLableAlertText = 'Заполнненая Вами форма находится на проверке, оповещение о результате будет отображатся в личном кабинете'
    }


    let statusReprLableText:string = 'Черновик';
    let statusReprLableClass:string = 'continue';
    let statusReprLableAlert:string = 'dark';
    let statusReprLableAlertText:string = 'Продолжите заполнение всех анкет для получения полного доступа к платформе';
    if (store.confirmInfo.isFormConfirmCompleated && store.confirmInfo.isFormConfirmValidated && store.confirmInfo.isFormConfirmValid) {
        statusReprLableText = 'Подтверждено';
        statusReprLableClass = 'done';
        statusReprLableAlert = 'success';
        statusReprLableAlertText = 'Заполнненая Вами форма прошла верификацию'
    } else if (store.confirmInfo.isFormConfirmCompleated && store.confirmInfo.isFormConfirmValidated && !store.confirmInfo.isFormConfirmValid) {
        statusReprLableText = 'НЕОБХОДИМА ДОРАБОТКА';
        statusReprLableClass = 'faild';
        statusReprLableAlert = 'danger';
        statusReprLableAlertText = 'Внесите указанные изменения в анкету и отправьте на повторную проверку'
    } else if (store.confirmInfo.isFormConfirmCompleated) {
        statusReprLableText = 'На проверке';
        statusReprLableClass = 'checking';
        statusReprLableAlert = 'warning';
        statusReprLableAlertText = 'Заполнненая Вами форма находится на проверке, оповещение о результате будет отображатся в личном кабинете'
    }

    return ( <>
        <div className="new-customer-title d-flex gap-4 py-4">
            <div className="new-customer-img"><img src={avatars} alt="Login page"/></div>
            <div className="new-customer-data">
                <h3 className="title">{store.user.companyName}</h3>
                <div><span className="rewards-number">00.00</span> рейтинг <TooltipInfo/></div>
            </div>
        </div>
        <div className="rewards d-flex gap-4 py-4">
            <div className="new-customer-img"><img src={beidges} alt="Beidges"/></div>
            <ModalLink class="rewards-link disabled" name="Пока нет наград"/>
        </div>
        <CardTitle>Для завершения процесса регистрации и получения полного доступа к функционалу Платформы, заполните анкету регистрации:</CardTitle>
        <Card className={`mb-3 ${statusReprLableClass}`}>
            <CardBody>
                {store.generelInfo.email ? (<>
                    <Row>
                        <Col>
                            <div className={`formStatusLable mb-4 ${statusReprLableClass}`}>
                                <div className="d-flex gap-2"><embed src={editWhite} /><span>{statusReprLableText}</span></div>
                            </div>
                            
                                Анкета представителя Юридического лица
                                <br/><br/>
                                <Alert variant={statusReprLableAlert} className="mb-5">
                                    {statusReprLableAlertText}
                                </Alert>
                            <Link className={store.confirmInfo && store.confirmInfo.isFormConfirmCompleated && !store.confirmInfo.isFormConfirmValidated ? 'legal-entity-border d-none': 'legal-entity-border'} to="/representative-entity">
                                <img src={edit} alt="Анкета представителя Юридического лица"/>
                            </Link>
                        </Col>
                    </Row>
                </>) : (<>
                    Анкета представителя Юридического лица
                    <br/><br/><br/><br/>
                    <Link className="legal-entity-border" to="/representative-entity"><img src={edit} alt="Анкета представителя Юридического лица"/></Link>
                </>)}
                
            </CardBody>
        </Card>
        <Card>
            <CardBody>
                {store.company.email && store.confirmInfo.isFormConfirmCompleated ? (<>
                    <Row>
                        <Col>
                            <div className={`formStatusLable mb-4 ${statusLableClass}`}>
                                <div className="d-flex gap-2"><embed src={editWhite} /><span>{statusLableText}</span></div>
                            </div>
                            
                                Анкета Юридического лица
                                <br/><br/>
                                <Alert variant={statusLableAlert} className="mb-5">
                                    {statusLableAlertText}
                                </Alert>
                            <Link className={store.bank && store.bank.isFormCompleated && !store.bank.isFormValidated ? 'legal-entity-border d-none': 'legal-entity-border'} to="/legal-entity">
                                <img src={edit} alt="Анкета Юридического лица"/>
                            </Link>
                        </Col>
                    </Row>
                </>) : (<>
                    Анкета Юридического лица
                    <br/><br/><br/><br/>
                    {store.confirmInfo.isFormConfirmCompleated ? (<><Link className="legal-entity-border" to="/legal-entity"><img src={edit} alt="Анкета Юридического лица"/></Link></>) : 
                    (<>
                    <Alert variant="warning" className="mb-5">Для доступа к анкета Юридического лица заполните, пожалуйста, анкету представителя Юридического лица</Alert>
                    </>)}
                    
                </>)}
                
            </CardBody>
        </Card>
    </> );
}
 
export default AccountNewCustomer;