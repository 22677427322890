import { FC, useState, useContext } from "react";
import { Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";
import {Context} from "../../../index";

const LeagalForm3: FC = () => {
    const {store} = useContext(Context);
    const validated = false;
    const [radioBeneficialOwnersStatus, setRadioBeneficialOwnersStatus] = useState<string>(store.owner.beneficialOwner ? store.owner.beneficialOwner : '');
    const [partControlOwnerChecked, setPartControlOwnerStatus] = useState<boolean>(store.owner.partControlOwner);
    const [fullCOntrolOwnerChecked, setfullCOntrolOwnerStatus] = useState<boolean>(store.owner.fullCOntrolOwner);

    const [form_Data, set_Form_Data] = useState({
        email: store.owner.email,
        organizationalStructureLegalEntity: store.owner.organizationalStructureLegalEntity,
        beneficialOwner: store.owner.beneficialOwner,
    });

    const handleRadionChange = (score: any, inputName: string) => {
        if (inputName === 'beneficialOwner') {
            setRadioBeneficialOwnersStatus(score);
        }

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };

    const handleCheckboxChange = (score: any, inputName: string) => {
        if (inputName === 'partControlOwner') {
            setPartControlOwnerStatus(score);
        } else {
            setfullCOntrolOwnerStatus(score);
        }
    };

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };
    return ( <>
        <CardText className="mt-5">Сведения о Единоличном исполнительном органе</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationOrganizationalStructureLegalEntity">
                    <FormLabel className='required'>Организационная структура юридического лица</FormLabel>
                    <Form.Control
                        type="text"
                        name="organizationalStructureLegalEntity"
                        value={form_Data.organizationalStructureLegalEntity}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9']+$"
                        className='w-100'
                        required
                        placeholder='Сведения о финансовом положении'
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9]+$/.test(form_Data.organizationalStructureLegalEntity)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Col lg={6} className="body-form-list">
                        <div className="body-form-list-title">Для акционерного общества:</div>
                        <ul>
                            <li className="body-form-list-item">общее собрание акционеров акционерного общества;</li>
                            <li className="body-form-list-item">совет директоров (наблюдательный совет) акционерного общества;</li>
                            <li className="body-form-list-item">единоличный исполнительный орган акционерного общества (директор, генеральный директор);</li>
                            <li className="body-form-list-item">коллегиальный исполнительный орган акционерного общества (правление, дирекция);</li>
                        </ul>
                    </Col>
                    <Col lg={6} className="body-form-list">
                        <div className="body-form-list-title">Для Общества с ограниченной ответственностью:</div>
                        <ul>
                            <li className="body-form-list-item">общее собрание участников общества с ограниченной ответственностью;</li>
                            <li className="body-form-list-item">совет директоров (наблюдательный совет) общества с ограниченной ответственностью;</li>
                            <li className="body-form-list-item">единоличный исполнительный орган общества с ограниченной ответственностью (генеральный директор, президент и другие);</li>
                            <li className="body-form-list-item">коллегиальный исполнительный орган общества с ограниченной ответственностью (правление, дирекция и другие).</li>
                        </ul>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <CardText className="mt-5">Сведения о Единоличном исполнительном органе</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Сведения о бенефициарных владельцах</FormLabel>
                    <Form.Check
                        type="radio"
                        name="beneficialOwner"
                        value="alone"
                        checked={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '') ? true : false}
                        onChange={e => handleRadionChange('alone', 'beneficialOwner')}
                        label="Единственным бенефициарным владельцем является единый исполнительный орган"
                        id="beneficialOwnerTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="beneficialOwner"
                        value="notAlone"
                        checked={radioBeneficialOwnersStatus === 'notAlone' ? true : false}
                        onChange={e => handleRadionChange('notAlone', 'beneficialOwner')}
                        label="Есть другие бенефициарные владельцы"
                        id="beneficialOwnerFalse"
                        className="radio"
                    />
                </Form.Group>
                <div className={(radioBeneficialOwnersStatus === 'alone' || radioBeneficialOwnersStatus === '') ? 'd-none' : ''}>
                    <hr className="card-divider mt-4 mb-3"/>
                    <div className="card-form-title mb-4">Бенефициар 1</div>
                    <Form.Group as={Col} className="mb-3">
                        <FormLabel>Влияние на деятельность юридического лица</FormLabel>
                        <Form.Check
                            type="checkbox"
                            name="partControlOwner"
                            label="Владеет 25% акций и более"
                            id="partControlOwnerTrue"
                            className="radio"
                            onChange={e => handleCheckboxChange(!partControlOwnerChecked, 'partControlOwner')}
                            checked={partControlOwnerChecked}
                        />
                        <Form.Check
                            type="checkbox"
                            name="fullCOntrolOwner"
                            label="Может контролировать действия юридического лица"
                            id="partControlOwnerFalse"
                            className="radio"
                            onChange={e => handleCheckboxChange(!fullCOntrolOwnerChecked, 'fullCOntrolOwner')}
                            checked={fullCOntrolOwnerChecked}
                        />
                    </Form.Group>
                    <Alert className="d-flex w-100 mt-3" variant="warning">Дополнительные поля для бенифициара в процессе разработки....</Alert>
                </div>
            </CardBody>
        </Card>
    </> );
}
 
export default LeagalForm3;