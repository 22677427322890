import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Tooltipimg from "../../img/icons/Info-circle.svg";

function TooltipInfo() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Информация
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button variant="link"><img src={Tooltipimg} alt="tooltipe"/></Button>
    </OverlayTrigger>
  );
}

export default TooltipInfo;