import {FC, useContext, useState} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../index";
import {IUser} from "../models/IUser";
import UserService from "../services/UserSrvice";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ModalLink from '../components/modals/ModalLink';
import AccountNewCustomer from '../components/account/AccountNewCustomer';


const Account: FC = () => {
    const {store} = useContext(Context);
    const [users, setUsers] = useState<IUser[]>([]);

    async function getUsers() {
        try {
            const response = await UserService.fetchUsers();
            setUsers(response.data);
        } catch (e) {
            console.log(e);
        }
    }


    return (<>
            {store.isAuth ? (
            <div className='organization-links'>
                <Container>
                    <div className='organization-link-box'>
                        <Link className='organization-link px-4 active' to="/account" >Документы</Link>
                        <ModalLink class="organization-link px-4 disabled" name="Информация"/>
                    </div>
                </Container>
            </div>) : <></>}
            <Container>
                {store.user.isActivated ? <AccountNewCustomer/> : ''}
                
                <div className="text-center">
                    <h2 className='my-5'>{store.isAuth ? '' : 'АВТОРИЗУЙТЕСЬ'}</h2>
                    {store.isAuth && !store.user.isActivated ? (<>
                        <h3 className='my-5'>{store.user.name}, добро пожаловать на платформу. <br/><br/>
                        Для продолжения регистрации перейдите, пожалуйста, по ссылке из письма, отправленного на указанный Вами адрес электронной почты.</h3>
                    </>) : (<></>)}
                </div>
                
                <div className='d-none'>
                    {store.user.isActivated ? (
                        <div>
                            <h4>Данные пользователя</h4>
                            Почта - {store.user.email}<br/>
                            ФИО - {store.user.name} {store.user.surname} {store.user.patronymic} <br/>
                            Статус - {store.user.status === 'myself' ? 'Я представляю свои интересы' : 'Я представляю интересы Юридического лица'}<br/>
                            Имя Компании - {store.user.companyName} <br/><br/>
                            
                            <button className="btn btn-primary" onClick={getUsers}>Получить пользователей (тестовая работа с БД)</button>
                        </div>
                    ) : (<></>)}

                    {users.map(user =>
                        <div key={user.email}>{user.email}</div>
                    )}
                </div>
            </Container>
        </>
    );
};
export default observer(Account);