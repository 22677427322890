import React, {FC, useContext, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import { Alert, Col, Container, Row } from 'react-bootstrap';

import ModalDefault from './modals/ModalDefault';
import mainImg from "../img/login/main.png";
import { Navigate, Link } from 'react-router-dom';

const myData = require('../locale/ru.json');

const LoginForm: FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const {store} = useContext(Context);
    
    
    
    return (<><Container>
        <Row className="form-container my-5 justify-content-between">
            <Col lg={12}><h4>Авторизация</h4></Col>
            <Col className="mb-5 mb-lg-0" lg={4}>
                {store.errorMessage !== '' ?
                    <Alert variant="danger">{store.errorMessage}</Alert> :
                    ''
                }
                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-email">Логин</label></div>
                    <input
                        className='w-100'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        type="text"
                        placeholder='Email'
                        id="form-container-email"
                    />
                </div>
                <div className='form-container-item mb-4'>
                    <div className='mb-1'><label htmlFor="form-container-password">Пароль</label></div>
                    <input
                        className='w-100'
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        type="password"
                        placeholder='Пароль'
                        id="form-container-password"
                    />
                </div>
                <div className='form-container-item my-5'>
                    <Row className="justify-content-between align-items-center">
                        <Col lg={3}>
                            <button className="btn btn-primary" onClick={() => store.login(email, password)}>
                                Логин
                            </button>
                        </Col>
                        <Col lg={9}>
                            <p className='mb-0'><span>У Вас нет учетной записи?</span> <Link to='/registration'>{myData.buttons.registration}</Link></p>
                        </Col>
                    </Row>
                </div>

                <div className='form-container-links my-5 d-flex'>
                    <ModalDefault name="Вход через Госуслуги" class="btn-outline-wrap-gold"/>
                    <ModalDefault name="Вход через ФНС" class="ms-5 btn-outline-wrap-gold"/>
                </div>

            </Col>
            <Col className="mb-5 mb-lg-0 form-container-img" lg={7}>
                <img  className="w-100" src={mainImg} alt="Login page"/>
            </Col>
        </Row>
        {store.isAuth ? <Navigate to="/account" replace={true} /> : ''}
        </Container></>
    );
};

export default observer(LoginForm);