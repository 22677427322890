import { createBrowserRouter } from "react-router-dom"
import App from "../App"
import Home from "../pages/Home"
import About from "../pages/About"
import LoginForm from "../components/LoginForm"
import RegisterForm from "../components/RegisterForm"
import Account from "../pages/Account"
import Cfa from "../pages/Cfa"
import Manufacturers from "../pages/Manufacturers"
import Investors from "../pages/Investors"

import {Context} from "../index";
import { useContext } from "react"
import Information from "../pages/Information"
import AccountCompanyForm from "../components/account/AccountCompanyForm"
import AccountRepresentativeForm from "../components/account/AccountRepresentativeForm"


const AccountComponent = () => {
    const {store} = useContext(Context);
    let accountPage = <Account/>
    if (!store.isAuth) {
        accountPage = <LoginForm/>;
    }
    return accountPage
}

const RegisterComponent = () => {
    const {store} = useContext(Context);
    let accountPage = <Account/>
    if (!store.isAuth) {
        accountPage = <RegisterForm/>;
    }
    return accountPage
}

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            { path: "", element: <Home/>},
            { path: "/about", element: <About/>},
            { path: "/registration", element: <RegisterComponent/>},
            { path: "/login", element: <AccountComponent/>},
            { path: "/account", element: <Account/>},
            { path: "/cfa", element: <Cfa/>},
            { path: "/manufacturers", element: <Manufacturers/>},
            { path: "/investors", element: <Investors/>},
            { path: "/information", element: <Information/>},
            { path: "/legal-entity", element: <AccountCompanyForm/>},
            { path: "/representative-entity", element: <AccountRepresentativeForm/>}
        ]
    }
])
