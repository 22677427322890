import { Link } from "react-router-dom";

const Manufacturers = () => {
    return ( <>
        <div className="text-center my-5">
        <h1>Производителям</h1>
        <h3>Страница в разработке ...</h3>
            <Link to="/">Home</Link>
        </div> 
        </>
);
}
 
export default Manufacturers;