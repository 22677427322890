import { Link } from "react-router-dom";

const Cfa = () => {
    return ( <>
        <div className="text-center my-5">
            <h1>Сделки и ЦФА</h1>
            <h3>Страница в разработке ...</h3>
            <Link to="/">Home</Link>
        </div> 
    </>);
}
 
export default Cfa;