import {FC, useContext, useState, useRef} from 'react';
import {observer} from "mobx-react-lite";

import {Context} from "../../index";
import { Link } from 'react-router-dom';
import { Button,  CardTitle, Col, Container, Form,  Row, Tab, Tabs } from 'react-bootstrap';
import ModalLink from '../modals/ModalLink';
import LeagalForm1 from './legalCompanyForms/legalForm1';
import LeagalForm2 from './legalCompanyForms/legalForm2';
import LeagalForm3 from './legalCompanyForms/legalForm3';
import LeagalForm4 from './legalCompanyForms/legalForm4';
import Modal from 'react-bootstrap/Modal';
import envelope from "../../img/icons/Envelope.svg";

const AccountCompanyForm: FC = () => {
    const {store} = useContext(Context);
    const [validated, set_Validated] = useState(false);
    let stepActiveItem:string = 'step1'
    let step2ActiveBtn:boolean = true;
    let step3ActiveBtn:boolean = true;
    let step4ActiveBtn:boolean = true;

    if (store.bank.email) {
        stepActiveItem = 'step1'
        step4ActiveBtn = false;
        step3ActiveBtn = false;
        step2ActiveBtn = false;
    } else if (store.owner.email) {
        stepActiveItem = 'step4'
        step4ActiveBtn = false;
        step3ActiveBtn = false;
        step2ActiveBtn = false;
    } else if (store.license.email) {
        stepActiveItem = 'step3'
        step3ActiveBtn = false;
        step2ActiveBtn = false;
    } else if (store.company.email) {
        stepActiveItem = 'step2'
        step2ActiveBtn = false;
    }

    const [key, setKey] = useState<any>(stepActiveItem);
    const [disableBtn2, setActive2] = useState<boolean>(step2ActiveBtn);
    const [disableBtn3, setActive3] = useState<boolean>(step3ActiveBtn);
    const [disableBtn4, setActive4] = useState<boolean>(step4ActiveBtn);

    const [show, setShow] = useState(false);
    const modalCompleateHendle = useRef<HTMLAnchorElement>(null);
    const handleClose = () => setShow(false);
    const handleShow = (e: any) => {e.preventDefault(); setShow(true)};

    const submitFn = (e: any) => {
        const form = e.currentTarget;
        e.preventDefault();
        if (form.checkValidity() === true) {
            e.stopPropagation();
            const currentBankAccount = form.elements.currentBankAccount.value;
            const bankName = form.elements.bankName.value;
            const bankBIK = form.elements.bankBIK.value;
            const corrAccount = form.elements.corrAccount.value;
            const bankInnCode = form.elements.bankInnCode.value;
            const bankKPP = form.elements.bankKPP.value;
            const platformRole = form.elements.platformRole.value;
            const uploadGosReg = form.elements.uploadGosReg.files[0];
            const uploadFounderDoc = form.elements.uploadFounderDoc.files[0];
            store.saveBank(store.user.email, store.user.id, currentBankAccount, bankName, bankBIK, corrAccount, bankInnCode, bankKPP, platformRole, uploadGosReg, uploadFounderDoc);
            if (modalCompleateHendle.current) {
                modalCompleateHendle.current.click();
            }
        }
        set_Validated(true);
        
    };

    const NextStep = (e: any) => {
        const form = e.currentTarget.closest('form');
        if (form.checkValidity() === true) {
            setKey(e.target.id);
            if (e.target.id === 'step2') {
                const organizationalLegalFor = form.elements.organizationalLegalFor.value;
                const shortCompanyName = form.elements.shortCompanyName.value;
                const companyPhone = form.elements.companyPhone.value;
                const companyEmail = form.elements.companyEmail.value;
                const companyWebSite = form.elements.companyWebSite.value;
                const ogrnName = form.elements.ogrnName.value;
                const innName = form.elements.innName.value;
                const uploadOgrn = form.elements.uploadOgrn.files[0];
                const uploadInn = form.elements.uploadInn.files[0];
                const kppName = form.elements.kppName.value;
                const okpoName = form.elements.okpoName.value;
                const okatoName = form.elements.okatoName.value;
                const okvedName = form.elements.okvedName.value;
                const regDate = form.elements.regDate.value;
                const nameRegAuthority = form.elements.nameRegAuthority.value;
                const gosRegRegion = form.elements.gosRegRegion.value;
                const gosRegCity = form.elements.gosRegCity.value;
                const gosRegStreet = form.elements.gosRegStreet.value;
                const gosRegHouse = form.elements.gosRegHouse.value;
                const gosRegCorpus = form.elements.gosRegCorpus.value;
                const gosRegApp = form.elements.gosRegApp.value;
                const gosPostalCode = form.elements.gosPostalCode.value;
                const isLegalAddSameAsGos = form.elements.isLegalAddSameAsGos.value;
                const isPostAddSameAsGos = form.elements.isPostAddSameAsGos.value;
                const legalRegRegion = form.elements.legalRegRegion.value;
                const legalRegCity = form.elements.legalRegCity.value;
                const postRegRegion = form.elements.postRegRegion.value;
                const postRegCity = form.elements.postRegCity.value;
                const legalRegStreet = form.elements.legalRegStreet.value;
                const legalRegHouse = form.elements.legalRegHouse.value;
                const legalRegCorpus = form.elements.legalRegCorpus.value;
                const legalRegApp = form.elements.legalRegApp.value;
                const legalPostalCode = form.elements.legalPostalCode.value;
                const postRegStreet = form.elements.postRegStreet.value;
                const postRegHouse = form.elements.postRegHouse.value;
                const postRegCorpus = form.elements.postRegCorpus.value;
                const postRegApp = form.elements.postRegApp.value;
                const postPostalCode = form.elements.postPostalCode.value;
                
                store.saveCompany(store.user.email, store.user.id, organizationalLegalFor, shortCompanyName, companyPhone, companyEmail, companyWebSite, ogrnName, innName, uploadOgrn, uploadInn, kppName, okpoName, okatoName, okvedName, regDate, nameRegAuthority, gosRegRegion, gosRegCity, gosRegStreet, gosRegHouse, gosRegCorpus, gosRegApp, gosPostalCode, isLegalAddSameAsGos, isPostAddSameAsGos, legalRegRegion, legalRegCity, postRegRegion, postRegCity, legalRegStreet, legalRegHouse, legalRegCorpus, legalRegApp, legalPostalCode, postRegStreet, postRegHouse, postRegCorpus, postRegApp, postPostalCode);
                setActive2(false);
            }
            else if (e.target.id === 'step3') {
                const typeActivity = form.elements.typeActivity.value;
                const numberAct = form.elements.numberAct.value;
                const dateAct = form.elements.dateAct.value;
                const officeAct = form.elements.officeAct.value;
                const dateEnd = form.elements.dateEnd.value;
                const uploadCopyScan = form.elements.uploadCopyScan.files[0];
                const isBankruptcy = form.elements.isBankruptcy.value;
                const isDoneBankruptcy = form.elements.isDoneBankruptcy.value;
                const isLiquidation = form.elements.isLiquidation.value;
                const isFailureObligation = form.elements.isFailureObligation.value;
                const isDebt = form.elements.isDebt.value;
                const financialInfo = form.elements.financialInfo.value;
                const businessReputation = form.elements.businessReputation.value;
                const uploadFinanceDoc = form.elements.uploadFinanceDoc.files[0];
                const uploadFinSituation = form.elements.uploadFinSituation.files[0];
                const uploadFinReputation = form.elements.uploadFinReputation.files[0];

                store.saveLicense(store.user.email, store.user.id, typeActivity, numberAct, dateAct, officeAct, dateEnd, uploadCopyScan, isBankruptcy, isDoneBankruptcy, isLiquidation, isFailureObligation, isDebt, financialInfo, businessReputation, uploadFinanceDoc, uploadFinSituation, uploadFinReputation);
                
                setActive3(false);
            } else if (e.target.id === 'step4') {
                const organizationalStructureLegalEntity = form.elements.organizationalStructureLegalEntity.value;
                const beneficialOwner = form.elements.beneficialOwner.value;
                const partControlOwner = form.elements.partControlOwner.checked;
                const fullCOntrolOwner = form.elements.fullCOntrolOwner.checked;
                store.saveOwner(store.user.email, store.user.id, organizationalStructureLegalEntity, beneficialOwner, partControlOwner, fullCOntrolOwner);

                setActive4(false);
            }
            set_Validated(false);
        } else {
            set_Validated(true);
        }
    }

    return (<>
        {store.isAuth ? (<>
        <div className='organization-links'>
            <Container>
                <div className='organization-link-box'>
                    <Link className='organization-link px-4 active' to="/account" >Документы</Link>
                    <ModalLink class="organization-link px-4 disabled" name="Информация"/>
                </div>
            </Container>
        </div>
        <Container>
            <CardTitle className='mt-5'>Анкета пользователя Юридического лица</CardTitle>
            <Tabs 
                defaultActiveKey={stepActiveItem}
                variant="underline"
                id="uncontrolled-tab-example" className="mb-3" justify
                activeKey={key}
                onSelect={(k) => setKey(k)}
                >
                <Tab eventKey="step1" title="Шаг">
                    <Form id="step-1" noValidate validated={validated}>
                        <LeagalForm1/>
                        <Button className='mt-4' variant="secondary" id="step2" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step2" title="Шаг" disabled={disableBtn2} >
                    <Form noValidate validated={validated}>
                        <LeagalForm2/>
                        <Button className='mt-5' variant="secondary" id="step3" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step3" title="Шаг" disabled={disableBtn3}>
                    <Form noValidate validated={validated}>
                        <LeagalForm3/>
                        <Button className='mt-5' variant="secondary" id="step4" onClick={NextStep}>Далее</Button>
                    </Form>
                </Tab>
                <Tab eventKey="step4" title="Шаг" disabled={disableBtn4}>
                    <Form noValidate validated={validated} onSubmit={submitFn}>
                        <LeagalForm4/>
                        <Row className='mt-5'><Col><Button variant="primary" id="form-submit" type="submit">Отправить на проверку</Button></Col></Row>
                    </Form>
                    <Link to="/" ref={modalCompleateHendle} onClick={handleShow}></Link>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Форма отправлена на проверку и будет обработана в ближайшее время</h4>
                            <Link to="/account" className="btn btn-secondary mt-4">Перейти на главную страницу</Link>
                        </Modal.Body>
                        <Modal.Footer>
                            <p>Подпишись на нашу рассылку, чтобы не пропустить обновление и самым первым попробовать новые функции!</p>
                            <div className='btn-outline-wrap-green mt-3 mb-4'>
                                <button className='btn btn-outline d-flex align-item-center'><img className="me-3" src={envelope} alt="Подписаться на новости"/> Подписаться на новости</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Tab>
            </Tabs>
        </Container>
        </>) : <></>}
    </>);
}
 
export default observer(AccountCompanyForm);