import $api from "../http";
import {AxiosResponse} from 'axios';
import {LicenseResponse} from '../models/response/LicenseResponse';

export default class LicenseService {
    static async saveLicense(
        email: string,
        user: string,
        typeActivity: string,
        numberAct: string,
        dateAct: string,
        officeAct: string,
        dateEnd: string,
        uploadCopyScan: File,
        isBankruptcy: string,
        isDoneBankruptcy: string,
        isLiquidation: string,
        isFailureObligation: string,
        isDebt: string,
        financialInfo: string,
        businessReputation: string,
        uploadFinanceDoc: File,
        uploadFinSituation: File,
        uploadFinReputation: File,

        ): Promise<AxiosResponse<LicenseResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('typeActivity', typeActivity);
        formData.append('numberAct', numberAct);
        formData.append('dateAct', dateAct);
        formData.append('officeAct', officeAct);
        formData.append('dateEnd', dateEnd);
        formData.append('uploadCopyScan', uploadCopyScan);
        formData.append('isBankruptcy', isBankruptcy);
        formData.append('isDoneBankruptcy', isDoneBankruptcy);
        formData.append('isLiquidation', isLiquidation);
        formData.append('isFailureObligation', isFailureObligation);
        formData.append('isDebt', isDebt);
        formData.append('financialInfo', financialInfo);
        formData.append('businessReputation', businessReputation);
        formData.append('uploadFinanceDoc', uploadFinanceDoc);
        formData.append('uploadFinSituation', uploadFinSituation);
        formData.append('uploadFinReputation', uploadFinReputation);

        return $api.post<LicenseResponse>('/licenseForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}