import $api from "../http";
import {AxiosResponse} from 'axios';
import {OwnerResponse} from '../models/response/OwnerResponse';

export default class OwnerService {
    static async saveOwner(
        email: string,
        user: string,
        organizationalStructureLegalEntity: string,
        beneficialOwner: string,
        partControlOwner: boolean,
        fullCOntrolOwner: boolean
        ): Promise<AxiosResponse<OwnerResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('organizationalStructureLegalEntity', organizationalStructureLegalEntity);
        formData.append('beneficialOwner', beneficialOwner);
        formData.append('partControlOwner', partControlOwner.toString());
        formData.append('fullCOntrolOwner', fullCOntrolOwner.toString());


        return $api.post<OwnerResponse>('/ownerForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}