import { FC, useContext,useRef,useState } from "react";
import { Alert, Card, CardBody, CardText, Col, Form, FormLabel, Row } from "react-bootstrap";

import {Context} from "../../../index";
import selectFile from "../../../img/icons/fileUpload.svg";

const LeagalForm2: FC = () => {
    const {store} = useContext(Context);
    const validated = false;
    const fileLimit = 1000000;

    // Data for Сведения о лицензиях file
    const filePickerLicenseCopyScan = useRef<HTMLInputElement>(null);
    const [errorFileSizeCopyScan, setErrorCopyScan] = useState<boolean>(false);
    const [filenameCopyScan, setFilenameCopyScan] = useState<string>('Загрузить скан-копию лицензии');
    const uploadCopyScanDBelemnt = store.license.uploadCopyScanPath;
    // Data for Сведения о финансовом положении file
    const filePickerLicenseFinanceDoc = useRef<HTMLInputElement>(null);
    const [errorFileSizeFinanceDoc, setErrorFinanceDoc] = useState<boolean>(false);
    const [filenameFinanceDoc, setFilenameFinanceDoc] = useState<string>('Загрузить скан-копию лицензии');
    const uploadFinanceDocDBelemnt = store.license.uploadFinanceDocPath;
    // Data for Сведения о деловой репутации/положение file
    const filePickerLicenseFinSituation = useRef<HTMLInputElement>(null);
    const [errorFileSizeFinSituation, setErrorFinSituation] = useState<boolean>(false);
    const [filenameFinSituation, setFilenameFinSituation] = useState<string>('Загрузить скан-копию лицензии');
    const uploadFinSituationDBelemnt = store.license.uploadFinSituationPath;
    // Data for Сведения о деловой репутации/репутация file
    const filePickerLicenseFinReputation = useRef<HTMLInputElement>(null);
    const [errorFileSizeFinReputation, setErrorFinReputation] = useState<boolean>(false);
    const [filenameFinReputation, setFilenameFinReputation] = useState<string>('Загрузить скан-копию лицензии');
    const uploadFinReputationDBelemnt = store.license.uploadFinReputationPath;

    const [radioBankruptcyStatus, setRadioBankruptcyStatus] = useState<string>(store.license.isBankruptcy);
    const [radioDoneBankruptcyStatus, setRadioisDoneBankruptcyStatus] = useState<string>(store.license.isDoneBankruptcy);
    const [radioLiquidationStatus, setRadioisLiquidationStatus] = useState<string>(store.license.isLiquidation);
    const [radioFailureObligationStatus, setRadioisFailureObligationStatus] = useState<string>(store.license.isFailureObligation);
    const [radioDebtStatus, setRadioisDebtStatus] = useState<string>(store.license.isDebt);
    
    const [form_Data, set_Form_Data] = useState({
        email: store.license.email,
        typeActivity: store.license.typeActivity,
        numberAct: store.license.numberAct,
        dateAct: store.license.dateAct,
        officeAct: store.license.officeAct,
        dateEnd: store.license.dateEnd,
        uploadCopyScan: store.license.uploadCopyScan,
        uploadCopyScanPath: uploadCopyScanDBelemnt,
        isBankruptcy: store.license.isBankruptcy,
        isDoneBankruptcy: store.license.isDoneBankruptcy,
        isLiquidation: store.license.isLiquidation,
        isFailureObligation: store.license.isFailureObligation,
        isDebt: store.license.isDebt,
        financialInfo: store.license.financialInfo,
        businessReputation: store.license.businessReputation,
        uploadFinanceDoc: store.license.uploadFinanceDoc,
        uploadFinanceDocPath: uploadFinanceDocDBelemnt,
        uploadFinSituation: store.license.uploadFinSituation,
        uploadFinSituationPath: uploadFinSituationDBelemnt,
        uploadFinReputation: store.license.uploadFinReputation,
        uploadFinReputationPath: uploadFinReputationDBelemnt,
    });

    const fileNameCopyScaDB = uploadCopyScanDBelemnt ? uploadCopyScanDBelemnt.split('-f-n-').pop() : '';
    const requiredCopyScan:boolean = uploadCopyScanDBelemnt ? false : true;

    const fileNameFinanceDocDB = uploadFinanceDocDBelemnt ? uploadFinanceDocDBelemnt.split('-f-n-').pop() : '';
    const requiredFinanceDoc:boolean = uploadFinanceDocDBelemnt ? false : true;

    const fileNameFinSituationDB = uploadFinSituationDBelemnt ? uploadFinSituationDBelemnt.split('-f-n-').pop() : '';
    const requiredFinSituation:boolean = uploadFinSituationDBelemnt ? false : true;

    const fileNameFinReputationDB = uploadFinReputationDBelemnt ? uploadFinReputationDBelemnt.split('-f-n-').pop() : '';
    const requiredFinReputation:boolean = uploadFinReputationDBelemnt ? false : true;
    

    const chngFn = (e: any) => {
        const { name, value } = e.target;
        e.preventDefault();
        set_Form_Data({
            ...form_Data,
            [name]: value,
        });
    };
    const uploadHandleChnageCopyScan = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorCopyScan(true);
            
            if (filePickerLicenseCopyScan.current) {
                filePickerLicenseCopyScan.current.value = "";
            }
        } else {
            setFilenameCopyScan(e.target.files[0].name);
            setErrorCopyScan(false);
        }
    }

    const handlePickCopyScan = () => {
        if (filePickerLicenseCopyScan.current) {
            filePickerLicenseCopyScan.current.click();
        }
    }

    const uploadHandleChnageFinanceDoc = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFinanceDoc(true);
            
            if (filePickerLicenseFinanceDoc.current) {
                filePickerLicenseFinanceDoc.current.value = "";
            }
        } else {
            setFilenameFinanceDoc(e.target.files[0].name);
            setErrorFinanceDoc(false);
        }
    }

    const handlePickFinanceDoc = () => {
        if (filePickerLicenseFinanceDoc.current) {
            filePickerLicenseFinanceDoc.current.click();
        }
    }

    const uploadHandleChnageFinSituation = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFinSituation(true);
            
            if (filePickerLicenseFinSituation.current) {
                filePickerLicenseFinSituation.current.value = "";
            }
        } else {
            setFilenameFinSituation(e.target.files[0].name);
            setErrorFinSituation(false);
        }
    }

    const handlePickFinSituation = () => {
        if (filePickerLicenseFinSituation.current) {
            filePickerLicenseFinSituation.current.click();
        }
    }

    const uploadHandleChnageFinReputation = (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0].size > fileLimit) {
            setErrorFinReputation(true);
            
            if (filePickerLicenseFinReputation.current) {
                filePickerLicenseFinReputation.current.value = "";
            }
        } else {
            setFilenameFinReputation(e.target.files[0].name);
            setErrorFinReputation(false);
        }
    }

    const handlePickFinReputation = () => {
        if (filePickerLicenseFinReputation.current) {
            filePickerLicenseFinReputation.current.click();
        }
    }

    const handleCheckboxChange = (score: any, inputName: string) => {
        if (inputName === 'isBankruptcy') {
            setRadioBankruptcyStatus(score);
        } else if (inputName === 'isDoneBankruptcy'){
            setRadioisDoneBankruptcyStatus(score);
        } else if (inputName === 'isLiquidation'){
            setRadioisLiquidationStatus(score);
        } else if (inputName === 'isFailureObligation'){
            setRadioisFailureObligationStatus(score);
        } else if (inputName === 'isDebt'){
            setRadioisDebtStatus(score);
        }

        set_Form_Data({
            ...form_Data,
            [inputName]: score,
        });
    };

    return ( <>
        <CardText className="mt-5">Сведения о лицензиях</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3" controlId="validationЕypeActivity">
                    <FormLabel className='required'>Вид деятельности</FormLabel>
                    <Form.Control
                        type="text"
                        name="typeActivity"
                        value={form_Data.typeActivity}
                        onChange={chngFn}
                        pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ']+$"
                        className='w-100'
                        placeholder='Вид деятельности'
                        required
                        isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ]+$/.test(form_Data.typeActivity)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Введите корректные данные
                    </Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validatioNnumberAct">
                        <FormLabel className='required'>Номер</FormLabel>
                        <Form.Control
                            type="text"
                            name="numberAct"
                            value={form_Data.numberAct}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Номер'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validatioDateAct">
                        <FormLabel className='required'>Дата выдачи</FormLabel>
                        <Form.Control
                            type="date"
                            name="dateAct"
                            onChange={chngFn}
                            max={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.dateAct}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Дата выдачи'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validatioOfficeAct">
                        <FormLabel className='required'>Кем выдана</FormLabel>
                        <Form.Control
                            type="text"
                            name="officeAct"
                            value={form_Data.officeAct}
                            onChange={chngFn}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Кем выдана'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={2} as={Col} className="mb-3" controlId="validatioDateEnd">
                        <FormLabel className='required'>Срок действия</FormLabel>
                        <Form.Control
                            type="date"
                            name="dateEnd"
                            onChange={chngFn}
                            min={new Date().toISOString().slice(0, 10)}
                            defaultValue={form_Data.dateEnd}
                            className='w-100 legal-form-1'
                            required
                            placeholder='Срок действия'
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="validationLicenseCopyScan">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageCopyScan}
                        accept=".pdf, .doc, .docx"
                        required={requiredCopyScan}
                        name="uploadCopyScan"
                        ref={filePickerLicenseCopyScan}
                        disabled={!requiredCopyScan}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickCopyScan}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameCopyScan}
                            <div className="small-txt">Формат файла должен соответсвовать [.dox, .docx, .pdf]</div>
                        </div>
                        
                        {fileNameCopyScaDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameCopyScaDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeCopyScan  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
            </CardBody>
        </Card>
        <CardText className="mt-5">Сведения о финансовом положении</CardText>
        <Card>
            <CardBody>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Ведется производство по делу о несостоятельности (банкротстве)</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isBankruptcy"
                        value="true"
                        checked={radioBankruptcyStatus === 'true' ? true : false}
                        onChange={e => handleCheckboxChange('true', 'isBankruptcy')}
                        label="Да"
                        id="isBankruptcyTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isBankruptcy"
                        value="false"
                        checked={radioBankruptcyStatus === 'true' ? false : true}
                        onChange={e => handleCheckboxChange('false', 'isBankruptcy')}
                        label="Нет"
                        id="isBankruptcyFalse"
                        className="radio"
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Имеются вступившие в силу решения судебных органов о признании юридического лица несостоятельным (банкротом)</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isDoneBankruptcy"
                        value="true"
                        checked={radioDoneBankruptcyStatus === 'true' ? true : false}
                        onChange={e => handleCheckboxChange('true', 'isDoneBankruptcy')}
                        label="Да"
                        id="isDoneBankruptcyTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isDoneBankruptcy"
                        value="false"
                        checked={radioDoneBankruptcyStatus === 'true' ? false : true}
                        onChange={e => handleCheckboxChange('false', 'isDoneBankruptcy')}
                        label="Нет"
                        id="isDoneBankruptcyFalse"
                        className="radio"
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Проводится процедура ликвидации</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isLiquidation"
                        value="true"
                        checked={radioLiquidationStatus === 'true' ? true : false}
                        onChange={e => handleCheckboxChange('true', 'isLiquidation')}
                        label="Да"
                        id="isLiquidationTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isLiquidation"
                        value="false"
                        checked={radioLiquidationStatus === 'true' ? false : true}
                        onChange={e => handleCheckboxChange('false', 'isLiquidation')}
                        label="Нет"
                        id="isLiquidationFalse"
                        className="radio"
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Есть факты неисполнения своих денежных обязательств по причине отсутствия денежных средств на банковских счетах</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isFailureObligation"
                        value="true"
                        checked={radioFailureObligationStatus === 'true' ? true : false}
                        onChange={e => handleCheckboxChange('true', 'isFailureObligation')}
                        label="Да"
                        id="isFailureObligationTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isFailureObligation"
                        value="false"
                        checked={radioFailureObligationStatus === 'true' ? false : true}
                        onChange={e => handleCheckboxChange('false', 'isFailureObligation')}
                        label="Нет"
                        id="isFailureObligationFalse"
                        className="radio"
                    />
                </Form.Group>
                <Form.Group as={Col} className="mb-3">
                    <FormLabel>Есть задолженность по уплате налоговых и иных обязательных платежей в бюджет и внебюджетные фонды Российской Федерации</FormLabel>
                    <Form.Check
                        type="radio"
                        name="isDebt"
                        value="true"
                        checked={radioDebtStatus === 'true' ? true : false}
                        onChange={e => handleCheckboxChange('true', 'isDebt')}
                        label="Да"
                        id="isDebtTrue"
                        className="radio"
                    />
                    <Form.Check
                        type="radio"
                        name="isDebt"
                        value="false"
                        checked={radioDebtStatus === 'true' ? false : true}
                        onChange={e => handleCheckboxChange('false', 'isDebt')}
                        label="Нет"
                        id="isDebtFalse"
                        className="radio"
                    />
                </Form.Group>
                <div className="my-2 cart-body-lable">Документы о финансовом положении</div>
                <Form.Group as={Col} className="mb-3" controlId="validationLicenseFinanceDoc">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageFinanceDoc}
                        accept=".pdf, .doc, .docx"
                        required={requiredFinanceDoc}
                        name="uploadFinanceDoc"
                        ref={filePickerLicenseFinanceDoc}
                        disabled={!requiredFinanceDoc}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickFinanceDoc}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameFinanceDoc}
                            <div className="small-txt">Формат файла должен соответсвовать [.dox, .docx, .pdf]</div>
                        </div>
                        
                        {fileNameFinanceDocDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFinanceDocDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeFinanceDoc  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
            </CardBody>
        </Card>
        <CardText className="mt-5">Сведения о финансовом положении</CardText>
        <Card>
            <CardBody>
                <Row>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationFinancialInfo">
                        <FormLabel className='required'>Сведения о финансовом положении</FormLabel>
                        <Form.Control
                            type="text"
                            name="financialInfo"
                            value={form_Data.financialInfo}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ']+$"
                            className='w-100'
                            placeholder='Сведения о финансовом положении'
                            required
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ]+$/.test(form_Data.financialInfo)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group lg={6} as={Col} className="mb-3" controlId="validationBusinessReputation">
                        <FormLabel className='required'>Сведения о деловой репутации</FormLabel>
                        <Form.Control
                            type="text"
                            name="businessReputation"
                            value={form_Data.businessReputation}
                            onChange={chngFn}
                            pattern="^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ']+$"
                            className='w-100'
                            placeholder='Сведения о деловой репутации'
                            required
                            isInvalid={ validated && !/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯa-zA-Z0-9 ]+$/.test(form_Data.businessReputation)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите корректные данные
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLicenseFinSituation">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageFinSituation}
                        accept=".pdf, .doc, .docx"
                        required={requiredFinSituation}
                        name="uploadFinSituation"
                        ref={filePickerLicenseFinSituation}
                        disabled={!requiredFinSituation}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickFinSituation}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameFinSituation}
                            <div className="small-txt">Формат файла должен соответсвовать [.dox, .docx, .pdf]</div>
                        </div>
                        
                        {fileNameFinSituationDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFinSituationDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeFinSituation  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group lg={6} as={Col} className="mb-3" controlId="validationLicenseFinReputation">
                    <Form.Control 
                        type="file"
                        className="hidden"
                        onChange={uploadHandleChnageFinReputation}
                        accept=".pdf, .doc, .docx"
                        required={requiredFinReputation}
                        name="uploadFinReputation"
                        ref={filePickerLicenseFinReputation}
                        disabled={!requiredFinReputation}
                    />
                    
                    <div className="filePicker d-flex align-items-center">
                        <div className="me-4 fileIcon" onClick={handlePickFinReputation}><img src={selectFile} alt="Select file"/></div>
                        <div className="fileLable">{filenameFinReputation}
                            <div className="small-txt">Формат файла должен соответсвовать [.dox, .docx, .pdf]</div>
                        </div>
                        
                        {fileNameFinReputationDB  ? <Alert className="d-flex w-100 mt-3" variant="warning">Файл {fileNameFinReputationDB} находится в обработке</Alert> : ''}
                        
                        {errorFileSizeFinReputation  ? <Alert className="d-flex w-100 mt-3" variant="danger">Размер файла больше лимита {fileLimit} bites</Alert> : ''}
                    </div>
                    <Form.Control.Feedback type="invalid">
                        Файл обязателен к загрузке
                    </Form.Control.Feedback>
                </Form.Group>
                </Row>
            </CardBody>
        </Card>
    </> );
}
 
export default LeagalForm2;