import $api from "../http";
import {AxiosResponse} from 'axios';
import {BankResponse} from '../models/response/BankResponse';

export default class BankService {
    static async saveBank(
        email: string,
        user: string,
        currentBankAccount: string,
        bankName: string,
        bankBIK: string,
        corrAccount: string,
        bankInnCode: string,
        bankKPP: string,
        platformRole: string,
        uploadGosReg: File,
        uploadFounderDoc: File
        ): Promise<AxiosResponse<BankResponse>> {
        
        const formData = new FormData();

        formData.append('email', email);
        formData.append('user', user);
        formData.append('currentBankAccount', currentBankAccount);
        formData.append('bankName', bankName);
        formData.append('bankBIK', bankBIK);
        formData.append('corrAccount', corrAccount);
        formData.append('bankInnCode', bankInnCode);
        formData.append('bankKPP', bankKPP);
        formData.append('platformRole', platformRole);
        formData.append('uploadGosReg', uploadGosReg);
        formData.append('uploadFounderDoc', uploadFounderDoc);

        return $api.post<BankResponse>('/bankForm', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
    }
}